import React from 'react';

import SubsiteHeader from '../components/molecules/SubsiteHeader';
import PageLayout from "../components/layout/PageLayout";
import Seo from '../components/seo';

import {
    DescribedCardsSections,
    CenteredImageListSection,
    SimpleDescriptionSection,
    ContentRectanglesSection,
    DescriptionVsTileslistSection,
    ListingTilesSection,
} from "../components/sections";

import {
    HEADER_CONFIG,
    DESCRIBED_CARDS_CONFIG,
    CENTERED_IMAGE_LIST_CONFIG,
    SIMPLE_DESCRIPTION_CONFIG,
    CONTENT_RECTANGLES_CONFIG,
    DESCRIPTION_VS_TILESLIST_CONFIG,
    LISTING_TILES_CONFIG,
} from "../content/about_us_content_config";

const AboutUsPage = ({ location }) => {
    const { headerTitle } = HEADER_CONFIG
    
    return (
        <>
            <Seo
                title="O nas | P.O.W. Ignaś i Helenka w Kaliskach"
                description="Dzieci w naszych domach otrzymują opiekę i wsparcie profesjonalnego zespołu wychowawców, psychologów i pedagogów."
            />

            <PageLayout location={ location }>
                <SubsiteHeader title={ headerTitle } />

                <CenteredImageListSection config={ CENTERED_IMAGE_LIST_CONFIG } />

                <DescribedCardsSections config={ DESCRIBED_CARDS_CONFIG } />

                <SimpleDescriptionSection description={ SIMPLE_DESCRIPTION_CONFIG } />

                <ContentRectanglesSection config={ CONTENT_RECTANGLES_CONFIG } />

                <ListingTilesSection config={ LISTING_TILES_CONFIG } />

                <DescriptionVsTileslistSection config={ DESCRIPTION_VS_TILESLIST_CONFIG } />
            </PageLayout>
        </>
    );
};
 
export default AboutUsPage;
