import {
    InfoIcon,
    CareIcon,
    FamilyIcon,
    HealthIcon,
    SchoolIcon,
    StarIcon,
    EduIcon,
} from "../assets/images";

import { colors } from "../styles/variables";

export const HEADER_CONFIG = {
    headerTitle: "O nas",
};

export const DESCRIBED_CARDS_CONFIG = {
    cards: [
        {
            id: 1,
            Icon: InfoIcon,
            content: 'Placówki "Ignaś" i "Helenka" są  placówkami typu socjalizacyjnego z miejscami interwencyjnymi. W każdej z nich jest po 14 miejsc, w tym po 3 miejsca interwencyjne.',
        },
        {
            id: 2,
            Icon: InfoIcon,
            content: 'Placówka "Ignaś" jest placówką  obsługującą, pod względem administracyjnym, organizacyjnym i specjalistycznym dla placówki "Helenka".',
        },
        {
            id: 3,
            Icon: InfoIcon,
            content: 'Dyrektor kieruje placówką obsługiwaną przy pomocy wyznaczonego wychowawcy koordynatora.',
        },
    ],
    description: 'Zadaniem placówki z miejscami interwencyjnymi (zgodnie z art. 103 pkt.1 ustawy o wspieraniu rodziny i systemie pieczy zastępczej) jest doraźna opieka nad dzieckiem w czasie trwania sytuacji kryzysowej, w szczególności placówka jest zobowiązana przyjąć  dziecko w przypadkach wymagających natychmiastowego zapewnienia dziecku opieki.',
};

export const CENTERED_IMAGE_LIST_CONFIG = {
    sectionTitle: "Nasze placówki",
    contentLeft: [
        {
            id: 1,
            Icon: CareIcon,
            text: "zapewniają dzieciom całodobową opiekę i wychowanie, zaspokajają niezbędne potrzeby, w szczególności emocjonalne, rozwojowe, zdrowotne, bytowe, społeczne i religijne",
        },  
        {
            id: 2,
            Icon: SchoolIcon,
            text: "zapewniają dostęp do kształcenia dostosowanego do wieku i możliwości rozwojowych dzieci",
        },
    ],
    contentRight: [
        {
            id: 1,
            Icon: FamilyIcon,
            text: "realizują przygotowany we współpracy z asystentem rodziny plan pomocy dziecku, umożliwiają kontakt dziecka z rodzicami i innymi osobami bliskimi chyba, że sąd postanowi inaczej, podejmują działania w celu powrotu dziecka do rodziny",
        },
        {
            id: 2,
            Icon: HealthIcon,
            text: "obejmują dziecko działaniami terapeutycznymi, zapewniają korzystanie z przysługujących świadczeń zdrowotnych",
        },
    ],
};

export const SIMPLE_DESCRIPTION_CONFIG = 'W placówkach umieszczane są dzieci powyżej 10. roku życia, wymagające szczególnej opieki lub mające trudności w przystosowaniu się do życia w rodzinie. Wychowankowie, którzy osiągnęli pełnoletność przebywający w pieczy zastępczej mogą przebywać nadal w placówce za zgodą dyrektora, nie dłużej jednak niż do ukończenia 25 roku życia, jeżeli kontynuują naukę.';

export const CONTENT_RECTANGLES_CONFIG = {
    sectionTitle: "Charakterystyka domów i otoczenia",
    content: [
        {
            id: 1,
            background: colors.ddGreen200,
            header: "Ignaś",
            text: "W domu dla dzieci „Ignaś” wychowankowie dysponują kuchnią wraz z jadalnią oraz salonem i łazienką, jak również pomieszczeniami gospodarczymi (pralnia, spiżarnia spożywcza) usytuowanymi na parterze domu. Są tam także dwa pomieszczenia przeznaczone dla obsługi administracyjno–biurowej. Na piętrze budynku jest pokój wychowawców oraz sześć pokoi dla wychowanków, w tym pięć pokoi dwuosobowych z łazienkami oraz jeden pokój czteroosobowy. Do dyspozycji dzieci jest salon, jak również łazienka ogólnodostępna. Na zewnątrz jest zabudowany taras wyposażony w ławy i ławki. Jest to miejsce do odpoczynku dla wychowanków.",
        },
        {
            id: 2,
            header: "Helenka",
            text: "W domu dla dzieci „Helenka” wychowankowie również dysponują kuchnią wraz z jadalnią oraz salonem i łazienką a także pomieszczeniami gospodarczymi (pralnią, spiżarnią spożywczą) usytuowanymi na parterze domu. Są tam również dwa pomieszczenia z przeznaczeniem na pokój wychowawców i pokój specjalistów. Specjaliści prowadzą swoją pracę zarówno na terenie placówki „Helenka”, jak i placówki „Ignaś”. Na piętrze jest 7 pokoi dwuosobowych z łazienkami dla wychowanków, salon oraz ogólnodostępna łazienka. Na zewnątrz jest zabudowany taras z miejscem do odpoczynku dla wychowanków.",
        },
        {
            id: 3,
            background: colors.ddGrey200,
            header: "Teren",
            text: "Na terenie posesji znajduje się boisko do gry w piłkę nożną z bramkami i siatkami zabezpieczającymi. Zostało ono sfinansowane przez sponsorów. Placówka posiada również Strefę Aktywności przekazaną placówce w trwały zarząd przez powiat w ramach programu Rozwoju Małej Infrastruktury Sportowo Rekreacyjnej o charakterze wielopokoleniowym – Otwarte Strefy Aktywności – OSA.",
        },
    ],
};

export const DESCRIPTION_VS_TILESLIST_CONFIG = {
    sectionTitle: "Rozwój i edukacja",
    leftContent: 'Placówki "Ignaś" i "Helenka" zbliżone są sposobem funkcjonowania do domów rodzinnych mających na celu podejmowanie działań służących do pracy nad usamodzielnieniem wychowanków lub powrotu ich do rodzin biologicznych. Są to miejsca gdzie młodzi ludzie uczą się samodzielności i przygotowują do życia po opuszczeniu placówki. Domy dla dzieci "Ignaś" i "Helenka" podejmują różnego rodzaju treningi, w tym: umiejętności społecznych, kulinarnych, higienicznych czy budżetowych, co pozwala wychowankom zrealizować ich indywidualne programy usamodzielnienia.',
    rightContent: {
        description: "Wychowankowie obu placówek realizują obowiązek szkolny w następujących szkołach:",
        list: [
            {
                id: 1,
                Icon: EduIcon,
                name: 'Katolickiej Publicznej Szkole Podstawowej "Julin" im. Heleny i Ignacego Jana Paderewskich w Kaliskach',
            },
            {
                id: 2,
                Icon: EduIcon,
                name: "Branżowej Szkole I Stopnia w Ostrówku",
            },
            {
                id: 3,
                Icon: EduIcon,
                name: "Liceum Ogólnokształcącym im. Marii Sadzewiczowej w Łochowie",
            },
            {
                id: 4,
                Icon: EduIcon,
                name: "Liceum Ogólnokształcącym im. Adama Mickiewicza w Węgrowie",
            },
            {
                id: 5,
                Icon: EduIcon,
                name: "Specjalnym Ośrodku Szkolno-Wychowawczym w Węgrowie",
            },
        ],
    },
};

export const LISTING_TILES_CONFIG = {
    sectionTitle: "Aktywność naszych podopiecznych",
    sectionSubtitle: "Czas wolny wychowankowie spędzają różnorodnie, w zależności od zainteresowań.",
    content: [
        {
            id: 1,
            Icon: StarIcon,
            description: "Na terenie posesji placówki znajdują się:",
            list: [
                {
                    id: 1,
                    text: "plac zabaw",
                },
                {
                    id: 2,
                    text: "boisko do gry w piłkę nożną",
                },
                {
                    id: 3,
                    text: "grill i palenisko do organizacji ogniska",
                },
                {
                    id: 4,
                    text: "stoły do ping-ponga",
                },
                {
                    id: 5,
                    text: "piłkarzyki",
                },
                {
                    id: 6,
                    text: "cymbergaj",
                },
            ],
        },
        {
            id: 1,
            Icon: StarIcon,
            description: "Wychowankowie spędzają czas również poza placówkami:",
            list: [
                {
                    id: 1,
                    text: 'zajęcia jazdy konnej w Stajni "Kaliska"',
                },
                {
                    id: 2,
                    text: "siłownia i wyjazdy na halę sportową przy Zespole Szkół Ponadpodstawowych im. Marii Sadzewiczowej w Łochowie",
                },
                {
                    id: 3,
                    text: "wyjazdy na basen, spływy kajakowe, wycieczki rowerowe",
                },
                {
                    id: 4,
                    text: "zajęcia z dogoterapii",
                },
                {
                    id: 5,
                    text: "zabawy Paintball",
                },
                {
                    id: 6,
                    text: "warsztaty ceramiczno-piekarniczo-hafciarskie i inne",
                },
                {
                    id: 7,
                    text: "wyjazdy na obozy na terenie całej Polski podczas ferii zimowych i wakacji",
                },
            ],
        },
        {
            id: 1,
            Icon: StarIcon,
            description: "W placówkach organizujemy także zajęcia:",
            list: [
                {
                    id: 1,
                    text: "kulinarne",
                },
                {
                    id: 2,
                    text: "plastyczne",
                },
                {
                    id: 3,
                    text: "sportowe",
                },
                {
                    id: 4,
                    text: "pielęgnacji zieleni",
                },
            ],
        },
    ],
};
